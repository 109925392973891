function Interests(){
    return (
        <div className="interests" dir="rtl">
        <h4 className="section-title">אטרקציות לאירועים בלתי נשכחים:</h4>
        <ul>
        <li><strong>שירותי מגנטים</strong> באיכות גבוהה לתיעוד הרגעים היפים.</li>
        <li><strong>תאורת אווירה</strong> מרהיבה שתאיר את האירוע שלכם.</li>
        <li><strong>בר אלכוהול פרימיום</strong> עם מבחר משקאות שיקפיץ את החגיגה.</li>
        <li><strong>עוגות מעוצבות בהתאמה אישית</strong>, בטעם וסטייל ייחודי לאירוע.</li>
    </ul>
    </div>
    );
}
export default Interests;