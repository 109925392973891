import profileImg from '../images/whitelogo.png'
function Profile(){
    return(
        <div className="profile">
            <div className="profile-img">
                <img rel="preload" src={profileImg} alt="profile-img"/>
            </div>
            <div className="profile-info">
                <p className="profile-name" dir='rtl'>שירותי מוסיקה והפקת אירועים</p>
            </div>
        </div>
    );
}
export default Profile;