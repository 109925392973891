// src/components/AddContactButton.js

import React from 'react';
import './App.css'; // Dedicated CSS for the button

const AddContactButton = () => {
  const contact = {
    firstName: "דיג'יי",
    lastName: "פניקס מוזיקה והפקות",
    phone: "+972542503515",
    email: "phoenix@djphoenix.co.il",
    photoURL: "https://i.ibb.co/VWYBV5P/whitelogo.png"
  };

  /**
   * Generates vCard content in VERSION 3.0 format, including a photo via URL.
   * @param {Object} contact - The contact details.
   * @param {string} photoURL - The URL of the photo to include.
   * @returns {string} - The formatted vCard string.
   */
  const generateVCard = (contact, photoURL) => {
    return [
      'BEGIN:VCARD',
      'VERSION:3.0',
      `N:${contact.lastName};${contact.firstName};;;`,
      `FN:${contact.firstName} ${contact.lastName}`,
      `TEL;TYPE=CELL:${contact.phone}`,
      `EMAIL:${contact.email}`,
      `PHOTO;VALUE=URL;TYPE=PNG:${photoURL}`, // Adds the photo via URL
      'END:VCARD',
    ].join('\n');
  };

  /**
   * Triggers the download of the vCard.
   * @param {string} vCardContent - The vCard data as a string.
   * @param {string} filename - The desired filename for the vCard.
   */
  const downloadVCard = (vCardContent, filename) => {
    const blob = new Blob([vCardContent], { type: 'text/vcard;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    // Create a temporary <a> element to initiate download
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  /**
   * Handles the button click event.
   * @param {Event} e - The click event.
   */
  const handleClick = (e) => {
    e.preventDefault();

    const vCard = generateVCard(contact, contact.photoURL);
    downloadVCard(vCard, 'dj_phoenix_contact.vcf');
  };

  return (
    <button className="cta-btn cta-btn-course"  onClick={handleClick} aria-label="שמור אותנו באנשי קשר">
      שמור אותנו באנשי קשר
      <i className="fas fa-user-plus icon"></i>
    </button>
  );
};

export default AddContactButton;
