import AddContactButton from "./addcontactbutton";

function CTA(){
    return(
        <div className="cta" dir="rtl">
            <a href="tel:+972542503515" class="custom-link">
                <div class="icon-container">
                      <i rel="preload" aria-hidden="true" class="fas fa-phone" id="phone-icon"></i>
                </div>
                <h3>חיוג</h3>
            </a>
            <a href="mailto:phoenix@djphoenix.co.il" class="custom-link mail-link">
                <div class="icon-container i">
                  <i rel="preload" class="fa-regular fa-envelope"></i>
                </div>
                <h3 style={{textAlign: "center"}}>שלח מייל</h3>
            </a>
            <a href="https://www.instagram.com/dj_phoenix.il" class="custom-link">
                <div class="icon-container">
                    <i rel="preload" aria-hidden="true" class="fab fa-instagram"></i>
                </div>
                <h3>אינסטגרם</h3>
            </a>
            <AddContactButton />          
           <a href="https://wa.me/972542503515?text=%D7%90%D7%94%D7%9C%D7%9F!%20%D7%99%D7%A9%20%D7%9C%D7%99%20%D7%90%D7%99%D7%A8%D7%95%D7%A2%20%D7%91%D7%A7%D7%A8%D7%95%D7%91%20%D7%95%D7%90%D7%A0%D7%99%20%D7%A8%D7%95%D7%A6%D7%94%20%D7%9C%D7%91%D7%A7%D7%A9%20%D7%94%D7%A6%D7%A2%D7%AA%20%D7%9E%D7%97%D7%99%D7%A8" className="cta-btn cta-btn-course">
                <span className="cta-btn-text">שלחו הודעה בווצאפ</span>
                <i rel="preload" aria-hidden="true" class="fab fa-whatsapp fa-lg"></i>
            </a>
        </div>    
    );
}
export default CTA;