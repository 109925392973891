import Profile from './Profile'
import Interests from './Interests'
import SocialMedia from './SocialMedia'
import CTA from './CTA'

function Card(){
    return(
        <div className="card">
        <Profile />
        <CTA />
        <Interests />
        <SocialMedia />
        </div>
    );
   
}
export default Card;